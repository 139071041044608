// Import dependencies
import React from "react"
import {Drawer, Menu, Divider} from "antd"
import {coming_soon_departments} from '../../mock-data/configurations'
import { navigate, Link } from "gatsby"

// Import styles
import "./categoty-drawer.css";
import DinoButton from "../../assets/images/dino-button.png";

// Import from Redux
import {connect} from "react-redux";
import {
    productsLoaded,
    productsFiltered,
    productsFilterCleared,
    categoryDrawerClose, collectionVehiclesLoaded, configsLoaded
} from "../../redux/actions/productAction"
import { challengesLoaded } from "../../redux/actions/challengesAction"

import {getFirebase} from "../../api/firebase";

export const LEVEL_DEPARTMENTS = 1
export const LEVEL_BRANDS = 2
export const LEVEL_CATEGORIES = 3
export const LEVEL_SUB_CATEGORIES = 4


const defaultState = {
    currentLevel: LEVEL_DEPARTMENTS,
    department: "",
    category: "",
    productCategory: "",
    productSubCategory: "",
    currentSelection: "",
}

/*
    left drawer to add category filter for the search
*/
class CategoryDrawer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          departments: [],
          brands: [],
          defaultState
        };
        this.onClose = this.onClose.bind(this)
        this.onMenuItemClick = this.onMenuItemClick.bind(this)
        this.onMenuBrandClick = this.onMenuBrandClick.bind(this)
        this.onBackClick = this.onBackClick.bind(this)
        this.afterVisibleChange = this.afterVisibleChange.bind(this)
        this.onClearFilter = this.onClearFilter.bind(this)
        this.showBrands = this.showBrands.bind(this)
        this.onMouseEnterMenuItem = this.onMouseEnterMenuItem.bind(this)
        this.onMouseLeaveMenuItem = this.onMouseLeaveMenuItem.bind(this)
        this.itemNotFoundFeedback = this.itemNotFoundFeedback.bind(this)
    }

    // Get data from firebase
    componentDidMount() {

        const {fetchedCallback = _ => _} = this.props;
        const app = import("firebase/app");
        const firestore = import("firebase/firestore");

        Promise.all([app, firestore])
            .then(([firebase]) =>
                getFirebase(firebase)
                    .firestore()
                    .collection("products")
                    .get()
                    .then(querySnapshot => {
                        if (Array.isArray(querySnapshot) && querySnapshot.length === 0)
                            fetchedCallback();
                        let products = []
                        querySnapshot.forEach(doc => {
                            // Get data
                            products = [...products, doc.data()]
                        });
                        this.props.dispatch(productsLoaded(products))

                    })
            )
            .catch(fetchedCallback);

        Promise.all([app, firestore])
            .then(([firebase]) =>
                getFirebase(firebase)
                    .firestore()
                    .collection("challenges")
                    .get()
                    .then(querySnapshot => {
                        if (Array.isArray(querySnapshot) && querySnapshot.length === 0)
                            fetchedCallback();
                        let challenges = []
                        querySnapshot.forEach(doc => {
                            // Get data
                            challenges = [...challenges, doc.data()]
                        });
                        this.props.dispatch(challengesLoaded(challenges))
    
                    })
            )
            .catch(fetchedCallback);

        Promise.all([app, firestore])
            .then(([firebase]) =>
                getFirebase(firebase)
                    .firestore()
                    .collection("collection_vehicles")
                    .get()
                    .then(querySnapshot => {
                        if (Array.isArray(querySnapshot) && querySnapshot.length === 0)
                            fetchedCallback();
                        let collectionVehiclesList = []
                        let collectionVehicles = {}
                        querySnapshot.forEach(doc => {
                            collectionVehiclesList = [...collectionVehiclesList, doc.data()]
                        });

                        collectionVehiclesList.sort((a, b) => a.position_in_list > b.position_in_list ? 1 : -1).map((vehicle, i) => {
                            collectionVehicles = {
                                ...collectionVehicles,
                                [vehicle.display_name]: vehicle
                            }
                        })

                        this.props.dispatch(collectionVehiclesLoaded(collectionVehicles))

                    })
            )
            .catch(fetchedCallback);

        Promise.all([app, firestore])
            .then(([firebase]) =>
                getFirebase(firebase)
                    .firestore()
                    .collection("configurations")
                    .get()
                    .then(querySnapshot => {
                        if (Array.isArray(querySnapshot) && querySnapshot.length === 0)
                            fetchedCallback();
                        let configs = null
                        querySnapshot.forEach(doc => {
                            configs = doc.data()
                        });
                        this.props.dispatch(configsLoaded(configs))

                    }).then(() => {
                      let brandsArray = Object.values(this.props.allProductsById)
                      .filter((product) => {
                        return product.Product.toLowerCase().replace("other ", "")
                      })
                      .map((product) => {
                        if ((product.Department == "Books" || product.Department == "Cookware" || product.Department == "Clothes") && !(product.Product.startsWith("OTHER"))) {
                          return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
                        } else if (product.Department == "Electronics" && product.Product.startsWith("OTHER") && product.Product !=  "OTHER " + product.SubCategory.toUpperCase()) {
                          return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
                        } else {
                          return false
                        }
                      })
                      .filter((value, index, self) => {
                        return self.indexOf(value) === index && value != "" ;
                      })
                      .sort()
                      ;

                      let departmentsArray = [
                        {title: "Electronics", categories : []},
                        {title: "Clothes", categories : []},
                        {title: "Books", categories : []},
                        {title: "Cookware", categories : []}
                      ];

                        Object.values(this.props.allProductsById)
                        .filter((product) => {
                          return product.Product.startsWith("OTHER")
                        })
                        .forEach((product) => {
                          if (product.Department == "Electronics") departmentsArray[0].categories.push(product.SubCategory);
                          else if (product.Department == "Clothes") departmentsArray[1].categories.push(product.SubCategory);
                          else if (product.Department == "Books") departmentsArray[2].categories.push(product.SubCategory);
                          else departmentsArray[3].categories.push(product.SubCategory);
                        });

                        for (let i = 0; i < 4; i++)
                        departmentsArray[i].categories = departmentsArray[i].categories
                        .filter((value, index, self) => {
                          return self.indexOf(value) === index && value != "" ;
                        })
                        .sort()
                        ;
                      this.setState({
                        brands: brandsArray,
                        departments: departmentsArray
                      });
                    })
            )
            .catch(fetchedCallback);

    }

    itemNotFoundFeedback = () => {
      this.props.dispatch(categoryDrawerClose());
      navigate("/brand/not-found");
    }

    onClearFilter = () => {
        this.props.dispatch(productsFilterCleared())
    };

    showBrands = () => {
      this.setState(() => ({ // currently at category level, go back to department level
          department: "",
          currentLevel: LEVEL_BRANDS
      }));
    }

    onClose = () => {
        this.props.dispatch(categoryDrawerClose())
    };

    onMouseEnterMenuItem = (e) => {
        this.setState(() => ({
            currentSelection: e.key
        }))
    };

    onMouseLeaveMenuItem = (e) => {
        this.setState(() => ({
            currentSelection: ""
        }))
    };

    afterVisibleChange = () => {
        // reset all states
        this.setState((prevState) => ({
            ...defaultState
        }))
    };

    onBackClick = () => {
        if (this.state.currentLevel === LEVEL_CATEGORIES || this.state.currentLevel === LEVEL_BRANDS) {
            this.setState((prevState) => ({ // currently at category level, go back to department level
                department: "",
                currentLevel: LEVEL_DEPARTMENTS
            }))
        } else if (this.state.currentLevel === LEVEL_SUB_CATEGORIES) {
            this.setState((prevState) => ({ // currently at sub category level, go back to category level
                productCategory: "",
                currentLevel: LEVEL_CATEGORIES
            }))
        }
    }

    onMenuBrandClick = (e) => {
      if (this.props.brandPage) {
        this.props.setBrand(e.key)
      }
      this.props.dispatch(categoryDrawerClose());
      navigate("/brand/" + e.key);
    }

    onMenuItemClick = (e) => {
        if (this.state.currentLevel === LEVEL_DEPARTMENTS) {
            if (this.state.departments[e.key].categories.length === 0) {
                this.props.dispatch(productsFiltered(e.key, '', ''))
            } else {
                const categoryTitle = this.state.departments[e.key].title;
                this.setState(() => ({
                    department: e.key,
                    category: categoryTitle,
                    currentLevel: LEVEL_CATEGORIES
                }))
            }
        }  else {
          let departmentFirebase = ["Electronics", "Cookware", "Books", "Clothes"];
          let categoryTitle = this.state.departments[this.state.department].categories[e.key];
          this.props.dispatch(productsFiltered(departmentFirebase[this.state.department], categoryTitle, categoryTitle))
        }
    };

    renderMenu() {
        let topLevelLabel = "DEPARTMENTS"
        let menuTitle = "Search all"
        let menuContent = this.props.productCategoryTree
        if (this.state.currentLevel === LEVEL_DEPARTMENTS) {
            topLevelLabel = "DEPARTMENTS"
            menuTitle = "Search all categories"
            menuContent = this.props.productCategoryTree
        } else if (this.state.currentLevel === LEVEL_BRANDS) {
            topLevelLabel = "BRANDS"
        } else if (this.state.currentLevel === LEVEL_CATEGORIES) {
            topLevelLabel = "CATEGORIES"
            menuTitle = this.state.category
            menuContent = this.props.productCategoryTree[this.state.department]
        } else if (this.state.currentLevel === LEVEL_SUB_CATEGORIES) {
            topLevelLabel = this.state.department.toUpperCase()
            menuTitle = this.state.productCategory
            menuContent = this.props.productCategoryTree[this.state.department][this.state.productCategory]
        }


        return (
            <div style={{height: "100%"}}>
                <div className="category-drawer__container-top-label category-drawer__container-top-label-department-level"
                     onClick={this.onBackClick}>
                    {this.state.currentLevel !== LEVEL_DEPARTMENTS &&
                    <div className="category-drawer__container-top-label-back-button">
                        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.39003 14.4967C8.91003 13.9767 8.91003 13.1367 8.39003 12.6167L3.2167 7.44335L8.39003 2.27002C8.91003 1.75002 8.91003 0.910015 8.39003 0.390015C7.87003 -0.129985 7.03003 -0.129985 6.51003 0.390015L0.39003 6.51002C-0.12997 7.03002 -0.12997 7.87002 0.39003 8.39002L6.51003 14.51C7.0167 15.0167 7.87003 15.0167 8.39003 14.4967Z" fill="#8697A3"/>
                        </svg>
                    </div>
                    }
                    <div className="category-drawer__container-top-label-text">{topLevelLabel}</div>
                </div>
                <div className="category-drawer__container-top-label-divider">
                    <Divider/>
                </div>
                <div style={this.state.currentLevel === LEVEL_DEPARTMENTS ? {} : {display: "none"}}>
                  <div className="category-drawer__container-menu-title category-drawer__container-menu-title_bold">
                    <div className="category-drawer__container-menu-title-text">
                      <div className="category-drawer__menu-item">
                        <div className="category-drawer__menu-item-name-sub">
                            Browse by brand
                        </div>
                        <div className="category-drawer__menu-item-arrow"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => {navigate("/galleria/all");this.onClearFilter();}}  style={this.state.currentLevel === LEVEL_DEPARTMENTS ? {} : {display: "none"}}>
                    <div className="category-drawer__container-menu-title category-drawer__container-menu-title-clickable category-drawer__container-menu-title-department-level ">
                        <div className="category-drawer__container-menu-title-text category-drawer__container-menu-title-text-department-level">
                            <div className="category-drawer__menu-item">
                                <div className="category-drawer__menu-item-name">Search all brands</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={this.showBrands}  style={this.state.currentLevel === LEVEL_DEPARTMENTS ? {} : {display: "none"}}>

                        <div className={this.state.currentLevel === LEVEL_DEPARTMENTS ?
                        "category-drawer__container-menu-title category-drawer__container-menu-title-clickable " +
                        "category-drawer__container-menu-title-department-level " :
                        "category-drawer__container-menu-title"
                    }>
                        <div className={this.state.currentLevel === LEVEL_DEPARTMENTS ? "" +
                            "category-drawer__container-menu-title-text category-drawer__container-menu-title-text-department-level " :
                            "category-drawer__container-menu-title-text"
                        }>
                            <div className="category-drawer__menu-item">
                                <div className={this.state.currentLevel === LEVEL_DEPARTMENTS ? "" +
                                    "category-drawer__menu-item-name" :
                                    "category-drawer__menu-item-name-sub"
                                }>Find your brand</div>
                                <div className="category-drawer__menu-item-arrow">
                                    {this.renderArrow()}
                                </div>
                            </div>

                            </div>
                        </div>

                </div>
                <div  style={this.state.currentLevel === LEVEL_DEPARTMENTS ? {} : {display: "none"}}>
                  <div className="category-drawer__container-menu-title category-drawer__container-menu-title_bold">
                    <div className="category-drawer__container-menu-title-text">
                      <div className="category-drawer__menu-item">
                        <div className="category-drawer__menu-item-name-sub">
                            Browse by category
                        </div>
                        <div className="category-drawer__menu-item-arrow"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={this.state.currentLevel === LEVEL_BRANDS ? {display: "none"} : {}} onClick={() => {if (menuTitle == "Search all categories") {navigate("/galleria/categories");this.onClearFilter();} else if (this.state.currentLevel === LEVEL_DEPARTMENTS) { this.onClearFilter(); }}}>
                    <div className={this.state.currentLevel === LEVEL_DEPARTMENTS ?
                        "category-drawer__container-menu-title category-drawer__container-menu-title-clickable " +
                        "category-drawer__container-menu-title-department-level " :
                        "category-drawer__container-menu-title"
                    }>
                        <div className={this.state.currentLevel === LEVEL_DEPARTMENTS ? "" +
                            "category-drawer__container-menu-title-text category-drawer__container-menu-title-text-department-level " :
                            "category-drawer__container-menu-title-text"
                        }><div className="category-drawer__menu-item">
                            <div className={this.state.currentLevel === LEVEL_DEPARTMENTS ? "" +
                                "category-drawer__menu-item-name" :
                                "category-drawer__menu-item-name-sub"
                            }>{menuTitle}</div>
                        </div></div>
                    </div>
                </div>

                <div className={this.state.currentLevel === LEVEL_DEPARTMENTS ? "category-drawer__container-menu-container category-drawer__container-menu-container-department" : "category-drawer__container-menu-container"}>
                    <Menu selectable={true} onClick={this.state.currentLevel === LEVEL_BRANDS ? this.onMenuBrandClick : this.onMenuItemClick} mode="vertical"
                          selectedKeys={[this.state.currentSelection]}
                          >

                        { (this.state.currentLevel === LEVEL_BRANDS) ?
                          <Menu.ItemGroup>
                              {this.state.brands.map((brand, i) => {
                                  return this.renderBrandItem(brand, i)
                              })}
                              <li className="item_not_found brand_menu" onClick={this.itemNotFoundFeedback}>
                                <img src={DinoButton}/>
                                <span>My brand isn’t here</span>
                              </li>
                          </Menu.ItemGroup>
                          :  ''
                        }
                        { (this.state.currentLevel === LEVEL_DEPARTMENTS) ?
                          <Menu.ItemGroup>
                              {this.state.departments.map((department, i) => {
                                  return this.renderMenuItem(department, i)
                              })}
                            </Menu.ItemGroup>
                          :  ''
                        }
                        { (this.state.currentLevel === LEVEL_CATEGORIES) ?
                          <Menu.ItemGroup>
                              {this.state.departments[this.state.department].categories.map((category, i) => {
                                  return this.renderCategoryItem(category, i);
                              })}
                            </Menu.ItemGroup>
                          :  ''
                        }




                    </Menu>
                </div>
            </div>

        )
    }

    renderArrow() {
      return (<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.3867 0.946667C0.866704 1.46667 0.866704 2.30667 1.3867 2.82667L6.56004 8L1.3867 13.1733C0.866704 13.6933 0.866704 14.5333 1.3867 15.0533C1.9067 15.5733 2.7467 15.5733 3.2667 15.0533L9.3867 8.93333C9.9067 8.41333 9.9067 7.57333 9.3867 7.05333L3.2667 0.933334C2.76004 0.426667 1.9067 0.426667 1.3867 0.946667Z" fill="#828282"/> </svg>)
    }

    renderMenuItem(department, i) {
        return (
            <Menu.Item key={i} disabled={false}
                       onMouseEnter={this.onMouseEnterMenuItem}
                       onMouseLeave={this.onMouseLeaveMenuItem}>
                <div className="category-drawer__menu-item">
                    <div className="category-drawer__menu-item-name">{department.title}</div>
                    {!(department.categories.length === 0 || this.state.currentLevel === LEVEL_SUB_CATEGORIES) &&
                    <div className="category-drawer__menu-item-arrow">
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.3867 0.946667C0.866704 1.46667 0.866704 2.30667 1.3867 2.82667L6.56004 8L1.3867 13.1733C0.866704 13.6933 0.866704 14.5333 1.3867 15.0533C1.9067 15.5733 2.7467 15.5733 3.2667 15.0533L9.3867 8.93333C9.9067 8.41333 9.9067 7.57333 9.3867 7.05333L3.2667 0.933334C2.76004 0.426667 1.9067 0.426667 1.3867 0.946667Z" fill="#828282"/> </svg>
                    </div>
                    }
                </div>
            </Menu.Item>
        )
    }

    renderCategoryItem(category, i) {
        return (
            <Menu.Item key={i} disabled={false}
                       onMouseEnter={this.onMouseEnterMenuItem}
                       onMouseLeave={this.onMouseLeaveMenuItem}>
                <div className="category-drawer__menu-item">
                    <div className="category-drawer__menu-item-name">{category}</div>
                </div>
            </Menu.Item>
        )
    }

    renderBrandItem(brandItem, i) {
        return (
            <Menu.Item key={brandItem.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s/g, '')}>
                <div className="category-drawer__menu-item">
                    <div className="category-drawer__menu-item-name">{brandItem}</div>
                </div>
            </Menu.Item>
        )
    }

    render() {
        return (
            <div className="category-drawer__container">
                <Drawer placement="left"
                        afterVisibleChange={this.afterVisibleChange}
                        visible={this.props.visible}>
                    <div className="category-drawer__container-close-button" onClick={this.onClose}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4 0.613295C16.88 0.0932946 16.04 0.0932946 15.52 0.613295L8.99996 7.11996L2.47996 0.599961C1.95996 0.079961 1.11996 0.079961 0.599961 0.599961C0.079961 1.11996 0.079961 1.95996 0.599961 2.47996L7.11996 8.99996L0.599961 15.52C0.079961 16.04 0.079961 16.88 0.599961 17.4C1.11996 17.92 1.95996 17.92 2.47996 17.4L8.99996 10.88L15.52 17.4C16.04 17.92 16.88 17.92 17.4 17.4C17.92 16.88 17.92 16.04 17.4 15.52L10.88 8.99996L17.4 2.47996C17.9066 1.97329 17.9066 1.11996 17.4 0.613295V0.613295Z" fill="#828282"/>
                        </svg>
                    </div>
                    {this.renderMenu()}
                </Drawer>
            </div>
        )
    }
}

// Connect redux to component
const mapStateToProps = state => ({
    productCategoryTree: state.product.productCategoryTree,
    productsLoaded: state.product.productsLoaded,
    visible: state.product.categoryDrawerVisible,
    allProductsById: state.product.productsIndexedById,
})

export default connect(mapStateToProps)(CategoryDrawer)
